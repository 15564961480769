import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 32,
    title: "Data Fetching in React:",
    desc: "Un Approccio Moderno ed Elegante",
    img: "/blog-image/fetching.jpg",
    page: "blog/react-fetching",
    data: "23 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Nel mondo dello sviluppo web, React si è distinto come una libreria ampiamente apprezzata per costruire interfacce utente accattivanti.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Nel mondo dello sviluppo web, React si è
                                        distinto come una libreria ampiamente
                                        apprezzata per costruire interfacce
                                        utente accattivanti. Uno dei compiti più
                                        ricorrenti nello sviluppo web è il
                                        recupero di dati da fonti esterne per
                                        visualizzarli nella tua applicazione. In
                                        questo articolo, ci addentreremo nel
                                        mondo del recupero dati in React JS,
                                        esplorando le tecniche moderne e le
                                        migliori pratiche in voga.
                                    </p>

                                    <p>
                                        Sia che tu stia sviluppando un feed di
                                        notizie, una piattaforma di e-commerce o
                                        un'app meteo, il recupero e la
                                        visualizzazione di dati da API, database
                                        o altre fonti è una necessità
                                        ineludibile come le tasse, e React offre
                                        un modo flessibile ed efficiente per
                                        gestire il recupero e la visualizzazione
                                        dei dati.
                                    </p>

                                    <h3>
                                        Il Metodo Tradizionale:
                                        componentDidMount
                                    </h3>

                                    <p>
                                        Negli albori di React, i componenti di
                                        classe erano la norma, e il modo più
                                        comune per recuperare dati era
                                        utilizzare il metodo del ciclo di vita
                                        componentDidMount. Ecco un esempio di
                                        come veniva fatto:
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`import React, { Component } from 'react';

class DataFetchingComponent extends Component {
    state = {
        data: null,
    };
    componentDidMount() {
        fetch('https://api.example.com/data')
        .then((response) => response.json())
        .then((data) => {
            this.setState({ data });
        });
    }
    render() {
        const { data } = this.state;
        return (
            <div>
                {data ? (
                    // Render data here
                ) : (
                    // Render loading state
                )}
            </div>
        );
    }
}
export default DataFetchingComponent;`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h3>
                                        Approccio Moderno: Utilizzo di useEffect
                                        e useState
                                    </h3>
                                    <p>
                                        I componenti funzionali, abbinati agli
                                        hook, offrono un modo più sintetico e
                                        leggibile per recuperare dati. L'hook
                                        useEffect è un protagonista in questo
                                        approccio, poiché consente di eseguire
                                        effetti collaterali nei tuoi componenti,
                                        come il recupero dei dati. Vediamo come
                                        funziona:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`import React, { useState, useEffect } from 'react';

function DataFetchingComponent() {
    const [data, setData] = useState(null);
    useEffect(() => {
            fetch('https://api.example.com/data')
            .then((response) => response.json())
            .then((data) => {
                    setData(data);
            });
    }, []);
    return (
            <div>
                {data ? (
                        // Render data here
                ) : (
                        // Render loading state
                )}
            </div>
    );
}
export default DataFetchingComponent;`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        In questo approccio moderno, definiamo
                                        un componente funzionale,
                                        DataFetchingComponent, e utilizziamo
                                        l'hook useState per gestire lo stato dei
                                        dati recuperati. L'hook useEffect si
                                        occupa dell'effetto collaterale del
                                        recupero dati. Fornendo un array di
                                        dipendenze vuoto ([]), ci assicuriamo
                                        che l'effetto venga eseguito una sola
                                        volta quando il componente viene
                                        montato.
                                    </p>
                                    <p>Vantaggi dell'Approccio Moderno:</p>
                                    <ol>
                                        <li>
                                            <p>
                                                Codice Più Pulito: I componenti
                                                funzionali e gli hook portano a
                                                un codice più pulito e
                                                leggibile, riducendo il
                                                boilerplate associato ai
                                                componenti di classe.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Prestazioni Migliorate: React
                                                ottimizza i componenti
                                                funzionali e gli hook,
                                                risultando in prestazioni
                                                migliori per la tua
                                                applicazione.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Test Più Facili: I componenti
                                                funzionali sono più facili da
                                                testare, in quanto sono semplici
                                                funzioni JavaScript. Puoi
                                                testare la logica all'interno
                                                del tuo componente più
                                                efficacemente.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Gestione dei Dati con gli Stati:
                                                Quando recuperi dati in React, è
                                                fondamentale gestire il suo
                                                stato in modo efficiente. Questo
                                                assicura che il tuo componente
                                                si ri-renderizzi quando i dati
                                                arrivano, mostrandoli
                                                all'utente.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Calcoli: Nel recupero dati in
                                                React, è importante tenere in
                                                considerazione i calcoli e
                                                l'elaborazione dei dati. Ad
                                                esempio, se stai gestendo dati
                                                numerici, potrebbe essere
                                                necessario eseguire calcoli per
                                                trasformare o aggregare i dati
                                                prima di renderizzarli nel tuo
                                                componente. Ecco un esempio su
                                                come calcolare la somma dei
                                                numeri recuperati da un'API:
                                            </p>
                                        </li>
                                    </ol>
                                    <MyCoolCodeBlock
                                        code={`import React, { useState, useEffect } from 'react';

function DataFetchingComponent() {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        fetch('https://api.example.com/data')
        .then((response) => response.json())
        .then((data) => {
                setData(data);
                const sum = data.reduce((acc, item) => acc + item, 0);
                setTotal(sum);
        }); 
    }, []);
    return (
        <div>
            {data.length > 0 ? (
                <div>
                    <p>Data: {data.join(', ')}</p>
                    <p>Total: {total}</p>
                </div>
            ) : (
                // Render loading state
            )}
        </div>
    );
}
export default DataFetchingComponent; `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Come abbiamo visto, gli hook useEffect e
                                        useState offrono un modo più elegante ed
                                        efficiente per gestire il recupero dei
                                        dati e la gestione dello stato.
                                        Adottando questo approccio moderno, puoi
                                        scrivere codice più pulito e più
                                        manutenibile, migliorando nel contempo
                                        le prestazioni complessive delle tue
                                        applicazioni React.
                                    </p>
                                    <p>
                                        In questo articolo, abbiamo coperto i
                                        concetti fondamentali del recupero dati
                                        in React e mostrato l'approccio moderno
                                        utilizzando componenti funzionali e
                                        hook. Con queste conoscenze, hai le basi
                                        per costruire applicazioni dinamiche in
                                        React, e se hai bisogno di una mano nel
                                        tuo progetto sai a chi rivolgerti :)
                                        <p>Buon coding a tutti!</p>
                                    </p>

                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>
                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
